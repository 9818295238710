<template>
    <div class="subtitle-2 text-center">
        <div class="font-weight-bold text-uppercase">{{ item.label }}</div>
        <div class="caption">{{ item.begin }} - {{ item.end }}</div>
    </div>
</template>

<script>
export default {
    name: 'ScheduleCallCell',
    props: {
        item: {
            type: Object,
            default: undefined
        }
    }
}
</script>

<style lang=scss>

</style>
